/* Palette generated by Material Palette - materialpalette.com/grey/teal */
body {
  background: #fafafa;
  font-family: 'roboto', sans-serif;
}
:root {
  --mdc-theme-primary: #212121;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-secondary: #2B78FE;
}
#root {
  margin: 0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
.link-unstyled {
  text-decoration: none!important;
}
.mdc-top-app-bar {
  z-index: 200;
}
.mdc-drawer--modal {
  z-index: 205;
}
.mdc-dialog {
  z-index: 210;
}
.mdc-menu {
  z-index: 201;
}
.sign-in-container {
  margin-top: 5vh;
}
.sign-in-card {
  border-radius: 5px;
  min-width: 300px;
  min-height: 100px;
}
.sign-in-form {
  position: relative;
  min-height: 200px;
}
.sign-in-form-overlay {
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.479);
}
.sign-in-title {
  padding: 1rem;
  background-color: #212121;
  color: #F5F5F5;
  border-radius: 5px 5px 0 0;
}
button:focus {
  outline: none;
}
label {
  margin-bottom: 0;
}
.bg-dark {
  background-color: #212121 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.bg-primary {
  background: #212121 !important;
}
.bg-secondary {
  background: #2B78FE !important;
}
.text-center {
  text-align: center;
}
.text-primary {
  color: #212121 !important;
}
.mdc-top-app-bar {
  background: url('./Assets/Images/bg-pattern.png'), #212121;
}
.menu-item {
  height: 150px;
  width: 150px;
  padding: 1rem;
}
.tournament-menu {
  border-collapse: separate;
  border-spacing: 1.5rem;
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  justify-content: center;
  transform: translateY(-1.5rem);
}
.table-app {
  border-collapse: collapse;
}
.table-app,
.table-app th,
.table-app tr,
.table-app td {
  padding: 0.2rem;
  font-size: 0.8rem;
  font-weight: 400;
}
.table-app td {
  text-align: center;
}
.table-app .table-border-bottom {
  border-bottom: 1px solid #BDBDBD;
}
.table-app .team {
  text-align: center;
  max-width: 7.5rem;
  overflow: hidden;
}
@media (min-width: 600px) {
  .table-app,
  .table-app th,
  .table-app tr,
  .table-app td {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
  }
  .table-app th {
    font-weight: 600;
  }
  .table-app .team {
    font-weight: 600;
    max-width: 10rem;
  }
}
.table-app-small {
  border-collapse: collapse;
}
.table-app-small,
.table-app-small th,
.table-app-small tr,
.table-app-small td {
  padding: 0.2rem;
  font-size: 0.8rem;
  font-weight: 400;
}
.table-app-small td {
  text-align: center;
}
.table-app-small .table-border-bottom {
  border-bottom: 1px solid #BDBDBD;
}
.table-app-small .team {
  text-align: center;
  max-width: 7.5rem;
  overflow: hidden;
}
.editor-box {
  padding: 1rem;
  box-shadow: 0 0 5px 0 #BDBDBD inset;
  background-color: #fff;
}
.reg-form {
  max-width: 600px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #212121;
  border-top: 5px solid #212121;
  border-bottom: 5px solid #212121;
}
.cover-image-container {
  width: 100%;
  height: auto;
  border-radius: 0 0 10px 10px;
}
.cover-image {
  width: 100%;
  height: auto;
  border-radius: 0 0 10px 10px;
}
@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.spin {
  animation: spin 1s cubic-bezier(0.5, 0, 0, 0.5) infinite forwards;
  transform-origin: center;
}
.tournament-page-content {
  max-width: 850px;
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1100;
}
.img-fluid-fixed {
  height: auto;
  max-width: 100%;
}
.jumbotron {
  background: url('./Assets/Images/bg-pattern.png'), linear-gradient(to bottom, #212121, #000);
  color: #FFFFFF;
  padding-bottom: 5rem;
  height: 100vh!important;
}
.jumbotron > div {
  padding-top: 2rem;
}
.jumbotron-heading {
  font-size: 24px;
}
.jumbotron-text {
  font-family: 'roboto', sans-serif;
  font-weight: 300;
}
.jumbotron-img {
  height: 128px;
  border-radius: 15px;
}
.faq {
  padding: 0;
  margin: 0;
}
.faq-question {
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}
.faq-question:hover {
  color: #212121;
}
@media (min-width: 600px) {
  .jumbotron-heading {
    font-size: 32px;
  }
  .faq-question {
    font-size: 22px;
  }
}
@media (min-width: 1024px) {
  .jumbotron-heading {
    font-size: 44px;
  }
  .faq-question {
    font-size: 28px;
  }
}
.action-button {
  display: inline-block;
  background: #fff;
  vertical-align: middle;
  border-radius: 5px;
  color: #212121;
  text-align: center;
  font-size: 1.3rem;
  padding: 0.5rem 1.2rem;
  width: 250px;
  height: auto;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  border: none;
  box-shadow: 0 1px 1.2px rgba(0, 0, 0, 0.034), 0 2px 2.3px rgba(0, 0, 0, 0.048), 0 5px 5px rgba(0, 0, 0, 0.06), 0 7px 7px rgba(0, 0, 0, 0.072);
}
.action-button.post-button {
  background: #212121;
  color: #fff;
}
.action-button:focus {
  outline: none;
}
.action-button:active {
  transform: translateY(5px);
}
.action-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.action-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.action-button:hover span {
  padding-right: 25px;
}
.action-button:hover span:after {
  opacity: 1;
  right: 0;
}
@media (max-width: 600px) {
  .action-button {
    width: 230px;
    font-size: 1rem;
  }
}
.dev-info {
  background-color: #1a39354b;
  color: #1A3935;
}
.dev-image {
  width: auto;
  height: 250px;
}
.list-style-tick {
  list-style: url('./Assets/Images/tick.svg');
}
.footer {
  background-color: #212121;
  color: #757575;
}
.footer .footer-subtitle {
  color: #F5F5F5;
  font-size: 1.3rem;
  font-weight: 300;
}
.footer a {
  color: #757575;
  text-decoration: none;
}
.footer a:hover {
  color: #F5F5F5;
}
.footer .footer-logo {
  width: 5rem;
  height: auto;
}
.footer .footer-link {
  font-size: 1.2rem;
}
.send-instagram {
  background-image: linear-gradient(to bottom left, #7822B7, #E1002B 80%, #E88446, #F1D01A);
  color: #fff;
  padding: 0.5rem;
  font-family: Roboto;
  border-radius: 5px;
  height: 2.4rem;
  transition: all 0.2s;
  margin-bottom: 5px;
}
.send-instagram:hover {
  cursor: pointer;
  background-image: linear-gradient(to bottom left, #7922b7e7, #e10029ef 80%, #e88446e0, #f1d11aea);
}
.fa-messenger {
  content: url('https://api.iconify.design/fa-brands:facebook-messenger.svg??color=%23fff&height=16');
  vertical-align: -0.125em;
}
.send-facebook {
  background-color: #0084FF;
  color: #fff;
  padding: 0.5rem;
  font-family: Roboto;
  border-radius: 5px;
  height: 2.4rem;
  transition: all 0.2s;
  margin-bottom: 5px;
}
.send-facebook:hover {
  cursor: pointer;
  background-color: #047beb;
}
.features .section-heading {
  margin-bottom: 100px;
}
.features .section-heading h2 {
  margin-top: 0;
}
.features .section-heading p {
  margin-bottom: 0;
}
.features .device-container,
.features .feature-item {
  max-width: 325px;
  margin: 0 auto;
}
.features .device-container {
  margin-bottom: 100px;
}
@media (min-width: 992px) {
  .features .device-container {
    margin-bottom: 0;
  }
}
.features .feature-item {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
.features .feature-item h3 {
  font-size: 30px;
}
.features .feature-item i {
  font-size: 80px;
  display: block;
  margin-bottom: 15px;
  background: linear-gradient(to left, #7b4397, #dc2430);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact-button {
  max-width: 200px;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 2px solid #F5F5F5;
  background-color: #212121;
  color: #F5F5F5;
  border-radius: 0.5rem;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  font-weight: 700;
}
.contact-button:hover {
  color: #1A3935;
  background-color: #F5F5F5;
}
.theme-default {
  background: #fff;
}
.stats-table-new .team-cell {
  padding: 0.2rem;
}
.stats-table-new .team-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.top-teams {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.top-teams .mdc-card {
  margin: 0.5rem 1rem 1rem 0.5rem;
}
.stats-card {
  border-radius: 10px;
  background-color: #fff;
}
.points-card {
  background: url('./Assets/Images/bg-pattern.png'), linear-gradient(to bottom, #212121, #000);
  padding: 0.5rem;
}
.theme-default .tourney-name,
.theme-default .th-title,
.theme-default .credit,
.theme-default .org-name {
  text-align: center;
  color: #fff;
}
.theme-default .tourney-logo {
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
}
.theme-default .th-title {
  font-size: 1.5rem;
  background: -webkit-linear-gradient(#FFDD00, #FBB034);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.theme-default .data-cell {
  text-align: center;
}
@media (max-width: 599px) {
  .theme-default .mdc-data-table__cell {
    padding: 5px!important;
    height: 2rem;
  }
  .theme-default .data-cell {
    padding: 5px!important;
    max-width: 4rem;
  }
  .theme-default .org-name {
    font-size: 1.3rem;
  }
}
@media (min-width: 600px) {
  .theme-default .tourney-name {
    color: #fff;
    font-size: 2.5rem;
  }
  .theme-default .org-name {
    font-size: 1.6rem;
  }
  .theme-default .th-title {
    color: #fff;
    font-size: 2rem;
  }
  .theme-default .tourney-logo {
    width: 6rem;
    height: 6rem;
    border-radius: 1rem;
  }
}
.about {
  background: url(./Assets/Images/bg-pattern.png), linear-gradient(to bottom, #212121, #000);
  color: #FFFFFF;
  background-attachment: fixed;
}
.about-table-container {
  background-color: rgba(255, 255, 255, 0.562);
  color: #000;
  padding: 1rem;
  border-radius: 0.5rem;
}
.about-table th,
.about-table td {
  border: 1px solid #212121;
  padding: 1rem 0.5rem;
}
.billing-form-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.billing-footer {
  background: var(--mdc-theme-primary);
  color: #fff;
  border-radius: 0 0 5px 5px;
}
.tr-list {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}
.tr-list .tr-list-item {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #b1b1b1;
}
.tr-list-item .tr-id {
  font-weight: 500;
}
.loader-container {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.loader-wrapper {
  display: flex;
  padding: 5px;
  justify-content: center;
  height: 100%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-p {
  display: flex;
  align-self: center;
  justify-self: center;
  border: 3px solid transparent;
  border-top-color: var(--mdc-theme-primary);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 2s linear infinite;
}
.loader-p::before {
  border: 3px solid transparent;
  border-top-color: var(--mdc-theme-secondary);
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  animation: spin 3s linear infinite;
}
.loader-p::after {
  border: 3px solid transparent;
  border-top-color: var(--mdc-theme-primary);
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  bottom: 15px;
  right: 15px;
  animation: spin 1.5s linear infinite;
}
.customizer-box {
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
}
.ptm-menu-card {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  height: 100%;
  width: 100%;
}
.ptm-menu-card .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ptm-menu-card .action .action-btn {
  width: 270px;
  margin: 5px;
}
.ptm-menu-card .match-csv-row {
  width: 280px;
  margin-top: 5px;
}
.pseudo-link {
  color: #212121;
  font-weight: 500;
  text-decoration: underline;
}
.pseudo-link:hover {
  cursor: pointer;
}
